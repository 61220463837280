<template>
  <b-row>
    <b-col>
      <b-row class="pt-2">
        <b-col md="4">
          <b-form-group
            label="Operations Manager"
            label-for="operationsManager"
          >
            <v-select
              v-if="!readOnly"
              id="operationsManager"
              v-model="OperationsManager"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="operationsManagerOptions"
              placeholder="Select Operations Manager"
              name="operationsManager"
              :disabled="readOnly"
              @input="(val) => clearInput(val, 'OperationsManager')"
            />
            <b-form-input
              v-else
              id="operationsManagerI"
              :value="OperationsManager && OperationsManager.title"
              placeholder=""
              name="operationsManagerI"
              :readonly="true"
            />
            <div
              class="phone-icon"
              :class="readOnly ? '' : 'editable'"
            >
              <feather-icon
                icon="PhoneIcon"
                size="16"
              />
              <div class="hover-text">
                <div>Phone: {{ OperationsManager && OperationsManager.Phone }}</div>
                <div>Mobile: {{ OperationsManager && OperationsManager.MobileSMS }}</div>
              </div>
            </div>
          </b-form-group>
        </b-col>
        <b-col
          md="4"
        >
          <b-form-group
            label="Research Manager"
            label-for="primaryCaseAssistant"
          >
            <v-select
              v-if="!readOnly"
              id="primaryCaseAssistant"
              v-model="PrimaryCaseAssistant"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="operationsManagerOptions"
              placeholder=""
              name="primaryCaseAssistant"
              :disabled="readOnly"
              @input="(val) => clearInput(val, 'PrimaryCaseAssistant')"
            />
            <b-form-input
              v-else
              id="primaryManager"
              :value="PrimaryCaseAssistant && PrimaryCaseAssistant.title"
              placeholder=""
              name="primaryManager"
              :readonly="true"
            />
            <div
              class="phone-icon"
              :class="readOnly ? '' : 'editable'"
            >
              <feather-icon
                icon="PhoneIcon"
                size="16"
              />
              <div class="hover-text">
                <div>Phone: {{ PrimaryCaseAssistant && PrimaryCaseAssistant.Phone }}</div>
                <div>Mobile: {{ PrimaryCaseAssistant && PrimaryCaseAssistant.MobileSMS }}</div>
              </div>
            </div>
          </b-form-group>
        </b-col>
        <b-col
          md="4"
        >
          <b-form-group
            label="SIU Manager"
            label-for="secondaryCaseAssistant"
          >
            <v-select
              v-if="!readOnly"
              id="secondaryCaseAssistant"
              v-model="SecondaryCaseAssistant"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="operationsManagerOptions"
              placeholder=""
              name="secondaryCaseAssistant"
              :disabled="readOnly"
              @input="(val) => clearInput(val, 'SecondaryCaseAssistant')"
            />
            <b-form-input
              v-else
              id="secondaryManager"
              :value="SecondaryCaseAssistant && SecondaryCaseAssistant.title"
              placeholder=""
              name="secondaryManager"
              :readonly="true"
            />
            <div
              class="phone-icon"
              :class="readOnly ? '' : 'editable'"
            >
              <feather-icon
                icon="PhoneIcon"
                size="16"
              />
              <div class="hover-text">
                <div>Phone: {{ SecondaryCaseAssistant && SecondaryCaseAssistant.Phone }}</div>
                <div>Mobile: {{ SecondaryCaseAssistant && SecondaryCaseAssistant.MobileSMS }}</div>
              </div>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <b-form-group
            label="RCS Manager"
            label-for="uspManager"
          >
            <v-select
              v-if="!readOnly"
              id="uspManager"
              v-model="USPManager"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="operationsManagerOptions"
              placeholder=""
              name="uspManager"
              :disabled="readOnly"
              @input="(val) => clearInput(val, 'USPManager')"
            />
            <b-form-input
              v-else
              id="uspManager"
              :value="USPManager && USPManager.title"
              placeholder=""
              name="uspManager"
              :readonly="true"
            />
            <div
              class="phone-icon"
              :class="readOnly ? '' : 'editable'"
            >
              <feather-icon
                icon="PhoneIcon"
                size="16"
              />
              <div class="hover-text">
                <div>Phone: {{ USPManager && USPManager.Phone }}</div>
                <div>Mobile: {{ USPManager && USPManager.MobileSMS }}</div>
              </div>
            </div>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Vendor Manager"
            label-for="vendorManager"
          >
            <v-select
              v-if="!readOnly"
              id="vendorManager"
              v-model="VendorManager"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="operationsManagerOptions"
              placeholder=""
              name="vendorManager"
              :disabled="readOnly"
              @input="(val) => clearInput(val, 'VendorManager')"
            />
            <b-form-input
              v-else
              id="vendorManager"
              :value="VendorManager && VendorManager.title"
              placeholder=""
              name="vendorManager"
              :readonly="true"
            />
            <div
              class="phone-icon"
              :class="readOnly ? '' : 'editable'"
            >
              <feather-icon
                icon="PhoneIcon"
                size="16"
              />
              <div class="hover-text">
                <div>Phone: {{ VendorManager && VendorManager.Phone }}</div>
                <div>Mobile: {{ VendorManager && VendorManager.MobileSMS }}</div>
              </div>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="getCase.CaseID">
        <b-col md="4">
          <b-form-group
            label="Account Manager"
            label-for="accountManager"
          >
            <b-form-input
              id="accountManager"
              v-model="AccountManager"
              placeholder=""
              name="accountManager"
              :readonly="true"
            />
            <div class="phone-icon">
              <feather-icon
                icon="PhoneIcon"
                size="16"
              />
              <div class="hover-text">
                <div>Phone: {{ AMContact && AMContact.Phone }}</div>
                <div>Mobile: {{ AMContact && AMContact.MobileSMS }}</div>
              </div>
            </div>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Branch Account Manager"
            label-for="branchAccountManager"
          >
            <b-form-input
              id="branchAccountManager"
              :value="BranchAccountManager && BranchAccountManager.title"
              placeholder=""
              name="branchAccountManager"
              :readonly="true"
            />
            <div class="phone-icon">
              <feather-icon
                icon="PhoneIcon"
                size="16"
              />
              <div class="hover-text">
                <div>Phone: {{ BranchAccountManager && BranchAccountManager.Phone }}</div>
                <div>Mobile: {{ BranchAccountManager && BranchAccountManager.MobileSMS }}</div>
              </div>
            </div>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="CSR"
            label-for="csr"
          >
            <b-form-input
              id="csr"
              v-model="CSR"
              placeholder=""
              name="csr"
              :readonly="true"
            />
            <div class="phone-icon">
              <feather-icon
                icon="PhoneIcon"
                size="16"
              />
              <div class="hover-text">
                <div>Phone: {{ CSRContact && CSRContact.Phone }}</div>
                <div>Mobile: {{ CSRContact && CSRContact.MobileSMS }}</div>
              </div>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="getCase.CaseID">
        <b-col md="4">
          <b-form-group
            label="Case Created"
            label-for="caseCreated"
          >
            <b-form-input
              id="caseCreated"
              v-model="CaseCreated"
              placeholder=""
              name="caseCreated"
              :readonly="true"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Case Closed"
            label-for="caseClosed"
          >
            <b-form-input
              id="caseClosed"
              v-model="CaseClosed"
              placeholder=""
              name="caseClosed"
              :readonly="true"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Case Reopened"
            label-for="caseReopened"
          >
            <b-form-input
              id="caseReopened"
              v-model="CaseReopened"
              placeholder=""
              name="caseReopened"
              :readonly="true"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-col>
  </b-row>

</template>

<script>
import {BCol, BFormGroup, BFormInput, BRow} from "bootstrap-vue";
import vSelect from "vue-select";
import APIService from "@core/utils/APIService";
import {mapGetters} from "vuex";

const apiService = new APIService();

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
  },
  data() {
    return {
      CaseManager: {},
      readOnly: false,
      OperationsManager: "",
      PrimaryCaseAssistant: "",
      SecondaryCaseAssistant: "",
      AccountManager: "",
      CSR: "",
      BranchAccountManager: "",
      USPManager: "",
      VendorManager: "",
      AMContact: null,
      CSRContact: null,
      CaseCreated: "",
      CaseClosed: "",
      CaseReopened: "",
      Users: [],

      operationsManagerOptions: [],
    }
  },
  computed: {
    ...mapGetters({
      getCase: "cases/getCase",
      getIsReadOnly: "cases/getIsReadOnly",
      getCurrentClient: "cases/getCurrentClient",
    }),
    isReadOnly() {
      return this.getIsReadOnly;
    },
    getCaseData() {
      return this.getCase
    },
    getCurrentClientData() {
      return this.getCurrentClient
    }
  },
  watch: {
    getCaseData(val) {
      this.fillData(val);
    },
    getCurrentClientData() {
      this.fillCaseManagement();
    },
    isReadOnly(val) {
      this.readOnly = val
    },
    OperationsManager(val) {
      if (val) {
        const value = this.filterValue(val);
        this.CaseManager = {...this.CaseManager, OperationsManagerUserID: value}
      }
    },
    PrimaryCaseAssistant(val) {
      if (val) {
        const value = this.filterValue(val);
        this.CaseManager = {...this.CaseManager, PrimaryCaseAssistantUserID: value}
      }
    },
    SecondaryCaseAssistant(val) {
      if (val) {
        const value = this.filterValue(val);
        this.CaseManager = {...this.CaseManager, SecondaryCaseAssistantUserID: value}
      }
    },
    USPManager(val) {
      if (val) {
        const value = this.filterValue(val);
        this.CaseManager = {...this.CaseManager, USPManagerID: value}
      }
    },
    VendorManager(val) {
      if (val) {
        const value = this.filterValue(val);
        this.CaseManager = {...this.CaseManager, VendorManagerID: value}
      }
    },
    BranchAccountManager(val) {
      if (val) {
        const value = this.filterValue(val);
        this.CaseManager = {...this.CaseManager, BranchAccountManagerID: value}
      }
    },

    CaseManager(val) {
      this.$emit("changedOperationsManager", val);
    }
  },
  async created() {
    this.readOnly = this.getIsReadOnly;
    try {
      const response = await apiService.get('users/user').then(res => res.data).catch(error => error)
      if (response) {
        this.operationsManagerOptions = response.Users.map(d => ({title: d.LastName + ", " + d.FirstName, value: d.UserAccountID, Phone: d.Phone, MobileSMS: d.MobileSMS}));
        this.Users = response.Users;
        this.fillCaseManagement();
      }
    } catch (err) {
      this.error = err
    }
  },
  methods: {
    filterValue(val) {
      let value = val ? val.value : "";
      if (val && !val.value) {
        if (val.length >= 1) {
          value = val[0].value
        }
      }
      return value;
    },
    dateTimeFormat(val) {
      if (val) {
        let dateTime = new Date(val).toLocaleTimeString();
        return val.substr(0, 10) + ', ' + dateTime;
      }
    },
    clearInput(val, item) {
      if (!val) {
        this[item] = null;
        let fieldName;
        if ("OperationsManager" === item) fieldName = "OperationsManagerUserID"
        if ("PrimaryCaseAssistant" === item) fieldName = "PrimaryCaseAssistantUserID"
        if ("SecondaryCaseAssistant" === item) fieldName = "SecondaryCaseAssistantUserID"
        if ("USPManager" === item) fieldName = "USPManagerID"
        if ("VendorManager" === item) fieldName = "VendorManagerID"
        if ("BranchAccountManager" === item) fieldName = "BranchAccountManagerID"
        this.CaseManager = {...this.CaseManager, [fieldName]: null}
      }
    },
    fillData(val) {
      this.CaseCreated = val.DateCreated ? this.dateTimeFormat(val.DateCreated) + ', ' + val.CreatedByFullname : '';
      this.CaseClosed = val.DateClosed ? this.dateTimeFormat(val.DateClosed) + ', ' + val.ClosedByFullname : '';
      this.CaseReopened = val.DateReopened ? this.dateTimeFormat(val.DateReopened) + ', ' + val.ReopenedByFullname : '';
    },

    fillCaseManagement() {
      if (this.Users && this.getCase.CaseID && this.getCurrentClient.ClientID) {
        this.OperationsManager = this.operationsManagerOptions.filter(i => i.value === this.getCase.OperationsManagerUserID)[0] || null;
        this.PrimaryCaseAssistant = this.operationsManagerOptions.filter(i => i.value === this.getCase.PrimaryCaseAssistantUserID)[0] || null;
        this.SecondaryCaseAssistant = this.operationsManagerOptions.filter(i => i.value === this.getCase.SecondaryCaseAssistantUserID)[0] || null;
        this.USPManager = this.operationsManagerOptions.filter(i => i.value === this.getCase.USPManagerID)[0] || null;
        this.VendorManager = this.operationsManagerOptions.filter(i => i.value === this.getCase.VendorManagerID)[0] || null;
        this.BranchAccountManager = this.operationsManagerOptions.filter(i => i.value === this.getCase.Branch.AccountManagerID)[0] || null;

        if (this.getCurrentClient.AMUserID) {
          const AccountManager = this.Users.filter(i => i.UserAccountID === this.getCurrentClient.AMUserID )[0] || null;
          if (AccountManager && AccountManager.LastName && AccountManager.FirstName) {
            this.AccountManager = AccountManager.LastName + ", " + AccountManager.FirstName;
            this.AMContact = {
              MobileSMS: AccountManager.MobileSMS,
              Phone: AccountManager.Phone
            }
          }
        }
        if (this.getCurrentClient.CSRUserID) {
          const CSR = this.Users.filter(i => i.UserAccountID === this.getCurrentClient.CSRUserID )[0] || null;
          if (CSR && CSR.LastName && CSR.FirstName) {
            this.CSR = CSR.LastName + ", " + CSR.FirstName;
            this.CSRContact = {
              MobileSMS: CSR.MobileSMS,
              Phone: CSR.Phone
            }
          }
        }

        this.CaseCreated = this.getCase.DateCreated ? this.dateTimeFormat(this.getCase.DateCreated) + ', ' + this.getCase.CreatedByFullname : '';
        this.CaseClosed = this.getCase.DateClosed ? this.dateTimeFormat(this.getCase.DateClosed) + ', ' + this.getCase.ClosedByFullname : '';
        this.CaseReopened = this.getCase.DateReopened ? this.dateTimeFormat(this.getCase.DateReopened) + ', ' + this.getCase.ReopenedByFullname : '';
      }
    },
  }
}
</script>

<style scoped>
.phone-icon:hover .hover-text {
  display: block;
}
.phone-icon.editable {
  right: 4.2rem;
}
.phone-icon {
  position: absolute;
  top: 1.9rem;
  right: 2rem;
  z-index: 2;
}
.hover-text {
  display: none;
  position: absolute;
  width: 17rem;
  z-index: 2;
  background: white;
  padding: 0.5rem;
  left: -15rem;
}
</style>
<template>
  <b-row class="pb-2 pt-2">
    <b-col>
      <b-row>
        <b-col>
          <b-form-group
            label="Claim / File Number"
            label-for="claimNumber"
          >
            <b-form-input
              id="claimNumber"
              v-model="ClaimFileNo"
              placeholder=""
              name="claimNumber"
              :readonly="readOnly"
              @blur.native="handleClaimFileNo"
              @keypress.enter="handleClaimFileNo"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Type of Claim / File"
            label-for="claimType"
          >
            <v-select
              id="claimType"
              v-model="ClaimFileType"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="claimType"
              placeholder=""
              name="claimType"
              :disabled="readOnly"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="getUserType !== 'Investigator' || getCurrentScopes.indexOf('st2.viewclientinfo') !== -1"
        >
          <b-form-group
            label="Insured (Policy Holder)"
            label-for="insured"
          >
            <b-form-input
              id="insured"
              v-model="InsuredText"
              placeholder=""
              name="insured"
              :readonly="readOnly"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="getUserType !== 'Investigator' || getCurrentScopes.indexOf('st2.viewclientinfo') !== -1"
        >
          <b-form-group
            label="Insured Representative"
            label-for="insuredRepresentative"
          >
            <b-form-input
              id="insuredRepresentative"
              v-model="InsuredContact"
              placeholder=""
              name="insuredRepresentative"
              :readonly="readOnly"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-if="getUserType === 'User' || (getUserType === 'Investigator' && getCurrentScopes.indexOf('st2.trustedinvestigator') !== -1)"
          md="3"
        >
          <b-form-group
            label="Claim Handler"
            label-for="claimHandler"
          >
            <b-form-input
              id="claimHandler"
              v-model="ClaimHandler"
              placeholder=""
              name="claimHandler"
              :readonly="readOnly"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Client Instructions"
            label-for="clientInstructions"
          >
            <b-form-checkbox
              id="contactedAccountManager"
              v-model="IsContactByAccountManager"
              name="contactedAccountManager"
              :disabled="readOnly"
            >
              Check here to be contacted by your Account Manager
            </b-form-checkbox>
            <b-form-checkbox
              id="preferPhysicalMedia"
              v-model="IsPreferPhysicalMedia"
              name="preferPhysicalMedia"
              :disabled="readOnly"
            >
              Check here if you prefer physical media
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            v-if="clientSubTypes.length"
            label="Client Claim SubTypes"
            label-for="clientInstructions"
          >
            <b-form-checkbox
              v-for="subType in clientSubTypes"
              :id="subType"
              :key="subType"
              v-model="subTypes[subType]"
              name="subType"
              :disabled="readOnly"
            >
              {{ subType }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col
          md="3"
          class="w-25 pl-1 pr-1"
        >
          <validation-provider
            #default="{ errors }"
            name="Case Received Source"
            rules="required"
          >
            <b-form-group
              label="Case Received Source"
              label-for="receivedSource"
              class="required-data"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-select
                v-if="getUserType !== 'Client'"
                id="receivedSource"
                v-model="ReceivedSource"
                label="title"
                :options="receivedSourceOptions"
                placeholder=""
                name="receivedSource"
                :disabled="readOnly"
              />
              <b-form-input
                v-else
                v-model="ReceivedSource"
                type="text"
                name="receivedSource"
                :readonly="true"
              />
              <small
                class="text-danger"
                :state="errors.length > 0 ? false : null"
              >
                {{ errors[0] }}
              </small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <div
            class="white-bg-container border-gray"
            :class="{emptyField: emptyField}"
          >
            <ServiceDropdown
              v-for="(service, index) of Services"
              id="serviceDropdown"
              :key="index"
              :index="index"
              @deleteService="deleteService"
              @updateService="updateService"
            />
            <b-button
              type="button"
              variant="primary"
              class="w-100"
              :disabled="readOnly"
              @click="addService()"
            >
              <span>Add Service</span>
            </b-button>
          </div>
        </b-col>
        <b-col md="9">
          <b-row>

            <b-col md="2">
              <validation-provider
                #default="{ errors }"
                name="Budget Hours"
                rules="required"
              >
                <b-form-group
                  label="Budget Hours"
                  class="required-data"
                  label-for="budgetHours"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-input
                    id="budgetHours"
                    v-model="BudgetHours"
                    type="number"
                    placeholder=""
                    name="budgetHours"
                    :readonly="readOnly"
                  />
                  <small
                    class="text-danger"
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="2">
              <b-form-group
                label="Budget $"
                label-for="budget"
              >
                <b-form-input
                  id="budget"
                  v-model="BudgetDollars"
                  type="number"
                  min="0"
                  placeholder=""
                  name="budget"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Due Date (if applicable)"
                label-for="caseDueDate"
              >
                <b-form-input
                  id="caseDueDate"
                  v-model="CaseDueDate"
                  type="date"
                  placeholder=""
                  name="caseDueDate"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Logistics"
                label-for="datesConducted"
              >
                <div
                  class="collapse-textarea"
                  :class="{expanded: !isExpandedConducted}"
                >
                  <textarea
                    v-model="DatesToBeConducted"
                    class="expandable-textarea"
                    :class="[isExpandedConducted ? 'not-expanded' : 'expanded']"
                    contentEditable
                    :readonly="readOnly"
                  />
                  <div @click="isExpandedConducted = !isExpandedConducted">
                    <span
                      class="expand-arrow mt-n1"
                      :class="[isExpandedConducted ? 'not-expanded' : 'expanded']"
                    >&#187;</span>
                  </div>
                </div>
              </b-form-group>
            </b-col>
            <b-col v-if="getCase.CaseID">
              <b-form-group
                label="Completed"
                label-for="completed"
              >
                <b-form-checkbox
                  id="completed"
                  v-model="isCompleted"
                  name="completed"
                  :disabled="readOnly"
                >
                  {{ CompletedDate | dateFormat}}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="2">
              <b-form-group
                label="Hours Remaining"
                label-for="hoursremaining"
              >
                <b-form-input
                  id="hoursremaining"
                  v-model="BudgetRemainingHours"
                  type="number"
                  placeholder=""
                  name="hoursremaining"
                  :readonly="true"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="getCase.CaseID"
              md="7"
            >
              <div>
                <router-link :to="'/cases/budget-adjustment/' + getCase.CaseID">
                  Budget Adjustment
                </router-link>
              </div>
              <div
                class="collapse-textarea"
                :class="{expanded: !isExpandedBudgetAdjLog}"
              >
                <textarea
                  v-model="budgetAdjustmentLogText"
                  class="expandable-textarea"
                  :class="[isExpandedBudgetAdjLog ? 'not-expanded' : 'expanded']"
                  contentEditable
                  :readonly="readOnly"
                />
                <div @click="isExpandedBudgetAdjLog = !isExpandedBudgetAdjLog">
                  <span
                    class="expand-arrow mt-n1"
                    :class="[isExpandedBudgetAdjLog ? 'not-expanded' : 'expanded']"
                  >&#187;</span>
                </div>
              </div>
            </b-col>

          </b-row>
          <b-row>
            <b-col md="2">
              <b-form-group
                label="NetSweep Feasibility"
                label-for="netSweepFeasibility"
                class="text-nowrap"
              >
                <b-form-radio-group
                  v-model="NetSweepFeasibility"
                  :options="options"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                label="RCS Feasibility"
                label-for="uspFeasibility"
                class="text-nowrap"
              >
                <b-form-radio-group
                  v-model="USPFeasibility"
                  :options="options"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="pl-1 pb-1">
            <b-col md="2">
              <b-row>
                <b-col md="0.5">
                  <strong>Yes</strong>
                </b-col>
                <b-col>
                  <strong>No</strong>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="2">
              <b-row>
                <b-col md="0.5">
                  <strong>Yes</strong>
                </b-col>
                <b-col>
                  <strong>No</strong>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        
      </b-row>
      <b-row style="margin-top: 1rem">
        <b-col>
          <b-form-group
            label="Other Services - Provide Details Below"
            label-for="otherServices"
          >
            <div
              class="collapse-textarea"
              :class="{expanded: !isExpandedOther}"
            >
              <textarea
                v-model="RequestedServicesOther"
                class="expandable-textarea"
                :class="[isExpandedOther ? 'not-expanded' : 'expanded']"
                contentEditable
                :readonly="readOnly"
              />
              <div @click="isExpandedOther = !isExpandedOther">
                <span
                  class="expand-arrow mt-n1"
                  :class="[isExpandedOther ? 'not-expanded' : 'expanded']"
                >&#187;</span>
              </div>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </b-col>
    <b-modal
      v-model="modalShow"
      hide-backdrop
      size="md"
      centered
    >
      <template #modal-header="">
        <div class="d-flex mt-1 mb-n3 text-center flex-column justify-content-center">
          <h2 class="text-center">
            <strong>There is a match for claim number<br>[<span v-text="ClaimFileNo" />]</strong>
          </h2>
          <h5 class="text-center">
            Do you wish to review this case?
          </h5>
        </div>
      </template>

      <template #modal-footer="">
        <div class="d-flex mb-1 justify-content-center flex-grow-1">
          <div class="w-50 d-flex justify-content-center">
            <b-button
              type="reset"
              variant="outline-secondary"
              size="md"
              @click="chancelCase()"
            >
              Ignore
            </b-button>
          </div>
          <div class="w-50 d-flex justify-content-center">
            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
              size="md"
              @click="reviewCase()"
            >
              View Case
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </b-row>
</template>

<script>
import {BButton, BCol, BFormCheckbox, BFormGroup, BFormInput, BFormRadioGroup, BFormSelect, BRow} from "bootstrap-vue";
import vSelect from "vue-select";
import ServiceDropdown from "@/views/cases/components/ServiceDropdown";
import {mapGetters} from "vuex";
import APIService from "@core/utils/APIService";
import { ValidationProvider } from "vee-validate";

const apiService = new APIService();

export default {
  components: {
    ServiceDropdown,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BFormCheckbox,
    BFormRadioGroup,
    BButton,
    vSelect,
    ValidationProvider,
  },
  props: {
    emptyField: {
      type: Boolean,
      default: false,
    }
  },
  filters: {
    dateFormat(val) {
      if (!val) return null
      const date = new Date(val);
      const options = {year: 'numeric', month: '2-digit', day: '2-digit'}
      return date.toLocaleDateString("en-US", options);
    },
  },
  data() {
    return {
      Assignment: {},
      ClaimFileNo: "",
      ClaimFileType: "",
      InsuredText: "",
      InsuredContact: "",
      ClaimHandler: "",
      IsContactByAccountManager: false,
      IsPreferPhysicalMedia: false,
      RequestedServices: "",
      Services: [{
        title: "",
        value: "",
      }],
      BudgetHours: 0,
      OldBudgetHours: 0,
      OldBudgetHoursRemaining: 0,
      BudgetRemainingHours: 0,
      isCompleted: null,
      CompletedDate: null,
      BudgetDollars: 0,
      CaseDueDate: "",
      DatesToBeConducted: "",
      RequestedServicesOther: "",
      BudgetAdjustments: [],
      matchCaseID: "",
      ReceivedSource: "",
      NetSweepFeasibility: null,
      USPFeasibility: null,
      options: [
        { text: '', value: true },
        { text: '', value: false },
      ],
      clientSubTypes: [],
      subTypes: {},
      ClientClaimSubTypes: "",

      readOnly: false,
      modalShow: false,
      isExpandedConducted: false,
      isExpandedBudgetAdjLog: false,
      isExpandedOther: false,

      firstLoad: true,

      claimType: [
        {
          title: "Auto",
          value: "Auto",
        },
        {
          title: "W/C",
          value: "W/C",
        },
        {
          title: "Liability",
          value: "Liability",
        },
        {
          title: "Property",
          value: "Property",
        },
        {
          title: "Disability",
          value: "Disability",
        },
        {
          title: "Medical Malpractice",
          value: "Medical Malpractice",
        },
        {
          title: "Other",
          value: "Other",
        },
      ],
      receivedSourceOptions: [
        {
          text: "Public Case Form",
          value: "Public Case Form",
        },
        {
          text: "Logged in Client",
          value: "Logged in Client",
        },
        {
          text: "Email",
          value: "Email",
        },
        {
          text: "Phone Call",
          value: "Phone Call",
        },
        {
          text: "DB Integration",
          value: "DB Integration",
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getAssignment: "cases/getAssignment",
      getCase: "cases/getCase",
      getIsReadOnly: "cases/getIsReadOnly",
      getAllCaseTabOpen: "cases/getAllCaseTabOpen",
      getCurrentClient: "cases/getCurrentClient",
      getUserType: "auth/getUserType",
      getCurrentScopes: "scopes/getCurrentScopes",
    }),
    getAssignmentData() {
      return this.getAssignment
    },
    isReadOnly() {
      return this.getIsReadOnly;
    },
    budgetAdjustmentLogText() {
      //return JSON.stringify(this.getCase.BudgetAdjustments);
      if (this.getCase.BudgetAdjustments !== undefined && this.getCase.BudgetAdjustments !== null)
      {
        return this.getCase.BudgetAdjustments.map((ba) => { return this.formatBudgetAdjustmentLogEntry(ba); }).join("\n\n");
      }
      return '';
    },
  },
  watch: {
    getAllCaseTabOpen: {
      immediate: true,
      handler(value) {
        const val = !!value
        this.isExpandedConducted = val
        this.isExpandedBudgetAdjLog = val
        this.isExpandedOther = val
      }
    },
    getCase(val) {
      if (val.CaseID) this.fillAssignment(this.getAssignment);
      if (val.BudgetHours && this.firstLoad) {
        this.OldBudgetHours = this.getCase.BudgetHours;
        this.OldBudgetHoursRemaining = this.getCase.BudgetRemainingHours || 0;
        this.firstLoad = false;
      }
    },
    getAssignmentData(val) {
      if (!this.firstLoad) this.fillAssignment(val);
    },
    isReadOnly(val) {
      this.readOnly = val
    },
    NetSweepFeasibility(val) {this.Assignment = {...this.Assignment, NetSweepFeasibility: val}},
    USPFeasibility(val) {this.Assignment = {...this.Assignment, USPFeasibility: val}},
    ClaimFileNo(val) {this.Assignment = {...this.Assignment, ClaimFileNo: val}},
    ClaimFileType(val) {this.Assignment = {...this.Assignment, ClaimFileType: val}},
    InsuredText(val) {this.Assignment = {...this.Assignment, InsuredText: val}},
    CompletedDate(val) {
        if (val) {
            if (!this.isCompleted) this.isCompleted = true;
        }
        this.Assignment = {...this.Assignment, CompletedDate: val}
    },
    isCompleted(val) {
        if (val) {
            if (this.CompletedDate === null) this.CompletedDate = this.dateComplete();
        } else if (this.isCompleted !== null) {
            this.isCompleted = null;
            this.CompletedDate = null;
        }
    },
    InsuredContact(val) {this.Assignment = {...this.Assignment, InsuredContact: val}},
    ClaimHandler(val) {this.Assignment = {...this.Assignment, ClaimHandler: val}},
    IsPreferPhysicalMedia (val) {this.Assignment = {...this.Assignment, IsPreferPhysicalMedia : val}},
    IsContactByAccountManager (val) {this.Assignment = {...this.Assignment, IsContactByAccountManager : val}},
    RequestedServices(val) {this.Assignment = {...this.Assignment, RequestedServices: val}},
    ReceivedSource(val) {this.Assignment = {...this.Assignment, ReceivedSource: val}},
    BudgetHours(val) {
      this.BudgetRemainingHours = this.OldBudgetHoursRemaining + (val - this.OldBudgetHours) ;
      this.Assignment = {...this.Assignment, BudgetHours: val, BudgetRemainingHours: this.BudgetRemainingHours}
    },
    BudgetDollars(val) {
      this.BudgetDollars = (!val || val < 0) ? 0 : val;
      this.Assignment = {...this.Assignment, BudgetDollars: this.BudgetDollars}
    },
    CaseDueDate(val) {this.Assignment = {...this.Assignment, CaseDueDate: val}},
    DatesToBeConducted(val) {this.Assignment = {...this.Assignment, DatesToBeConducted: val}},
    RequestedServicesOther(val) {this.Assignment = {...this.Assignment, RequestedServicesOther: val}},
    subTypes: {
        handler(val) {
            const toTextArr = [];
            for (let key in val) {
                if (val[key]) toTextArr.push(key)
            }
            this.ClientClaimSubTypes = toTextArr.join(',')
        },
        deep: true
    },
    ClientClaimSubTypes(val) {this.Assignment = {...this.Assignment, ClientClaimSubTypes: val}},
    Services(val) {
      const items = [];
      val.map(item => items.push(item.value))
      this.RequestedServices = items.join(',')
    },
    Assignment() {
      this.$emit("changeAssignment", this.Assignment);
    }
  },
  async created() {
    this.readOnly = this.getIsReadOnly;
    try {
      this.fillAssignment(this.getAssignment);
      if (this.getCase.BudgetHours) {
        this.OldBudgetHours = this.getCase.BudgetHours;
        this.OldBudgetHoursRemaining = this.getCase.BudgetRemainingHours || 0;
      }
      if (!this.getCase.CaseID && this.getUserType === "Client") {
        this.ReceivedSource = "Logged in Client"
      }
    } catch (err) {
      this.error = err
    }
  },
  methods: {
    addService() {
      const item = {
        title: "",
        value: "",
      };
      this.Services.push(item)
    },
    deleteService(index) {
      this.Services.splice(index, 1);
    },
    updateService(val, index) {
      this.Services.splice(index, 1, val);
    },
    handleClaimFileNo() {
      if (!this.$store.state.auth.IsLoggedIn) { return; }

      if (this.ClaimFileNo && this.ClaimFileNo.length >= 2) {
        apiService
            .get("case/lookup/byclaim/" + this.ClaimFileNo)
            .then((response) => {
              if ((response.data.length === 0) || (response.data.length === 1 && this.getCase.CaseID && response.data[0].CaseID === this.getCase.CaseID)) {
                console.log(response)
              } else {
                if (response.data.length > 1 && this.getCase.CaseID && response.data[0].CaseID === this.getCase.CaseID) {
                  this.matchCaseID = response.data[1].CaseID;
                } else {
                  this.matchCaseID = response.data[0].CaseID;
                }
                this.modalShow = true;
              }
            })
      }
    },
    formatBudgetAdjustmentLogEntry(ba) {
      var adjText = ba.TimestampDisplay + ', ' + ba.User + ', ';

      if (ba.AdjustHours !== undefined && ba.AdjustHours !== null && ba.AdjustHours !== 0) {
        adjText = adjText + 'Budget (Hours) has been adjusted by ' + ba.AdjustHours + ' hours, ';
      }

      if (ba.AdjustDollars !== undefined && ba.AdjustDollars !== null && ba.AdjustDollars !== 0) {
        adjText = adjText + 'Budget (Dollars) has been adjusted by $' + ba.AdjustDollars + ', ';
      }

      adjText = adjText + ba.Notes;

      return adjText;
    },
    dateComplete() {
      const date = new Date();
      const options = {year: 'numeric', month: '2-digit', day: '2-digit'}
      return date.toLocaleDateString("en-US", options);
    },
    fillAssignment(val) {
      this.subTypes = {};
      this.clientSubTypes = []
      const {ClientClaimSubTypes} = this.getCurrentClient;
      if (ClientClaimSubTypes && ClientClaimSubTypes.indexOf(',') !== -1) {
          this.clientSubTypes = ClientClaimSubTypes.split(',')
      } else if (ClientClaimSubTypes) {
          this.clientSubTypes = [ClientClaimSubTypes]
      }
      this.clientSubTypes.map(item => {
          this.subTypes = {...this.subTypes, [item]: val.ClientClaimSubTypes && val.ClientClaimSubTypes.indexOf(item) !== -1}
      })

      const items = [];
      let services;
      if (val.RequestedServices && val.RequestedServices.indexOf(',') !== -1) {
        services = val.RequestedServices.split(',');
      } else {
        services = [val.RequestedServices];
      }
      services.map((item) => {
        items.push({title: item, value: item})
      })
      this.Services = items;
      this.NetSweepFeasibility = val.NetSweepFeasibility;
      this.USPFeasibility = val.USPFeasibility;
      this.ClaimFileNo = val.ClaimFileNo;
      this.ClaimFileType = val.ClaimFileType;
      this.InsuredText = val.InsuredText;
      this.CompletedDate = val.CompletedDate;
      this.InsuredContact = val.InsuredContact;
      this.ClaimHandler = val.ClaimHandler;
      this.IsContactByAccountManager = val.IsContactByAccountManager;
      this.IsPreferPhysicalMedia = val.IsPreferPhysicalMedia;
      this.RequestedServices = val.RequestedServices;
      this.ReceivedSource = val.ReceivedSource;
      this.BudgetHours = val.BudgetHours;
      this.BudgetRemainingHours = val.BudgetRemainingHours;
      this.BudgetDollars = val.BudgetDollars;
      this.CaseDueDate = val.CaseDueDate.substr(0, 10);
      this.DatesToBeConducted = val.DatesToBeConducted;
      this.RequestedServicesOther = val.RequestedServicesOther;
    },
    reviewCase() {
      this.modalShow = !this.modalShow;
      let route = this.$router.resolve('/cases/' + this.matchCaseID + '/detail');
      window.open(route.href, '_blank');
    },
    chancelCase() {
      this.modalShow = !this.modalShow
    },
  }

}
</script>

<style scoped>
.border-gray {
  border: 1px solid #c7c7cc !important
}
.emptyField {
  border: 1px solid red !important;
}
.custom-select:disabled {
  color: #323232;
}
</style>